<template>
  <div class="page">
    <div class="account-form">
      <el-form label-width="80px" :model="ruleForm" :rules="rules" ref="ruleForm" size="mini" class="account-ruleForm el-form">
        <el-form-item label="姓名：" prop="sysName">
          <el-input maxlength="10" v-model="ruleForm.sysName" placeholder="请填写姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机：" prop="phone">
          <el-input v-model="ruleForm.phone" maxlength="11" disabled placeholder="请填写手机"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="tipss">打<span class="red">*</span> 的项目为必填项</div>
          <div class="tipss">tips：创建账号后请将密码复制到其它区域保存，该系统为了保证账号安全，只可联系平台管理员修改，平台管理员微信：<span class="forestgreen">jintian5453</span></div>
        </el-form-item>
      </el-form>
      <div class="from-foot">
        <el-button type="return"  @click="returnPage()">返回</el-button>
        <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="submitForm('ruleForm')">修改</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import sysService from '@/globals/service/detection/Sys.js'

export default {

  data () {
    return {
      routerName: '',
      fullscreenLoading: false,
      roleTypeOptions: [
        {
          value: 11,
          label: '筛查员'
        },
        {
          value: 10,
          label: '学校管理员'
        }
      ],
      ruleForm: {
        id: '',
        sysName: '',
        phone: '',
        roleType: null
      },
      rules: {
        sysName: [
          { required: true, message: '请填写姓名', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请填写手机', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    for (const key in this.$route.query) {
      this.ruleForm[key] = this.$route.query[key]
    }
    this.ruleForm.roleType = Number(this.ruleForm.roleType)
    // const row = this.$route.query
    // this.ruleForm = {
    //   id: row.id,
    //   sysName: row.sysName,
    //   phone: row.phone,
    //   roleType: Number(row.roleType)
    // }
  },
  created () {
    this.routerName = this.$route.meta.title ? this.$route.meta.title : ''
  },
  methods: {
    goTo (path, query) {
      this.$router.push({ path: path, query: query })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const that = this
          that.fullscreenLoading = true
          const ruleForm = this.ruleForm
          ruleForm.roleType = Number(ruleForm.roleType)
          sysService.update(ruleForm).then(res => {
            this.$message({
              message: res.errMsg,
              type: 'success',
              duration: 1000,
              onClose: function () {
                that.fullscreenLoading = false
                that.$router.push({ path: '/detection/accountManagement' })
              }
            })
          }).catch(() => {
            that.fullscreenLoading = false
          })
        } else {
          this.$message.error('请填入相应信息！')
          return false
        }
      })
    },
    returnPage () {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/login' })
        return false
      } else {
        this.$router.push({ path: '/detection/accountManagement' })
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.account-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px 0;
  box-sizing: border-box;
  max-width: 500px;

}
.header-container{
  width: 100%;
  height: 43px;
  border-bottom: 1px solid #f5f5f5;
  .header-section{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .route-name{
      line-height: 43px;
      font-size: 12px;
      color: #51B25F;
    }
    .button-section{
      display: flex;
      align-items: center;
    }
  }
}
.page{
    padding: 20px 10px;
    background-color: #fff;
    min-height: 100%;
    color: #333;
  }
  .red{
    color: #f50e0e;
  }
</style>
